import React from 'react';
import Breadcrumbs from "../breadcrumbs";
import Formulario from '../Contact'
import { graphql, useStaticQuery } from 'gatsby';
import LateralContact from '../lateralContact';

const Main = () => {
    const { realEstate } = useStaticQuery(graphql`
      query {
          realEstate {
              name
              global_email
              global_phone
              keys {
                captcha
              }
              social{
                facebook
                instagram
                twitter
                youtube
                linkedin
              }
              branch_office{
                name
                address
                city
                region
                broker_id
                contact {
                  phone
                  mail
                  whatsapp
                }
              }
              sections {
                    services{
                        title
                        subtitle
                        paragraph
                    }
                    footer {
                        title_contact
                        title_social
                        title_newsletter
                        title_office
                        button_contact{
                            link
                            value
                        }
                    }
              }
          }
    }`)

    return(
        <section className="contact-main">
            <div className="container-fluid">
                <div className="row height">
                    <div className="col-12 d-flex align-items-center">
                        <div className="row mt-lg-0 pt-lg-0 pt-5 content-main">
                            <div className="col-12">
                                <Breadcrumbs
                                    props={[
                                    { name: realEstate.name, route: "/", location: "" },
                                    { name: "Contacto", route:   "", location: ""},
                                    ]}
                                />
                            </div>
                            <div className="col-12">
                                <h1>Contacto</h1>
                            </div>
                            <div className="col-lg-8 order-2 order-lg-1">
                                <Formulario from={'contact'} />
                            </div>
                            <div className="lateral-contact col-lg-3 offset-lg-1 text-center contact-nav d-lg-flex d-none align-items-center order-1 order-lg-2">
                                 <LateralContact/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
} 

export default Main;